/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Injectable} from '@angular/core';
import {ErrorService} from '@dv/kitadmin/core/errors';
import type {DialogModel} from '@dv/shared/angular';
import {LogFactory} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import type {BsModalRef, ModalOptions} from 'ngx-bootstrap/modal';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ConfirmComponent} from './component/confirm/confirm.component';
import {DateDialogComponent} from './component/date-dialog/date-dialog.component';
import {TerminateDialogComponent} from './component/terminate-dialog/terminate-dialog.component';
import type {ConfirmDialogModel} from './models/ConfirmDialogModel';
import type {DateDialogModel} from './models/DateDialogModel';
import type {DeleteDialogModel} from './models/DeleteDialogModel';
import type {TerminateDialogModel} from './models/TerminateDialogModel';

const LOG = LogFactory.createLog('DialogService');

type DialogComponent<T extends DialogModel> = (new (...args: any[]) => T);

@Injectable({
    providedIn: 'root',
})
export class DialogService {

    public constructor(
        private readonly bsModalService: BsModalService,
        private readonly errorService: ErrorService,
        private readonly translator: Translator,
    ) {
    }

    public openDialog<T extends DialogModel, T2 extends T>(
        component: DialogComponent<T2>,
        initialState: T,
        options?: { class: 'full-width' },
    ): BsModalRef {
        this.errorService.clearAll();

        const config: ModalOptions = {
            class: `dv-modal-window ${options?.class ?? ''}`,
            backdrop: 'static',
            ignoreBackdropClick: true,
            initialState,
        };

        const bsModalRef: BsModalRef = this.bsModalService.show(component as any, config);

        bsModalRef.onHide?.subscribe({
            next: () => this.errorService.clearAll(),
            error: (err: unknown) => LOG.error(err),
        });

        return bsModalRef;
    }

    public openConfirmDialog(
        options: ConfirmDialogModel,
    ): BsModalRef {
        return this.openDialog(ConfirmComponent, options);
    }

    /**
     * Opens the delete modal dialog with an optional text describing the object to delete.
     */
    public openDeleteDialog(
        options: DeleteDialogModel,
    ): BsModalRef {

        const translatedEntityText = this.translator.instant(options.entityText);
        const deleteText = this.translator.instant(
            'COMMON.CONFIRM_DELETE_ENTITY',
            {entity: translatedEntityText});

        const confirmOptions: ConfirmDialogModel = {
            title: deleteText,
            confirm: options.confirm,
            cancel: options.cancel,
            confirmActionText: 'COMMON.LOESCHEN',
        };

        return this.openConfirmDialog(confirmOptions);
    }

    public openTerminateDialog(options: TerminateDialogModel): BsModalRef {
        return this.openDialog(TerminateDialogComponent, options);
    }

    public openDateDialog(options: DateDialogModel): BsModalRef {
        return this.openDialog(DateDialogComponent, options);
    }
}
