@if (title()) {
    <h2>{{ title() }}</h2>
}

<dvlib-spinner data-testid="centered-spinner" class="centered-spinner" [show]="isLoading()"/>

@if (isEmpty()) {
    <div class="collapsible-panel-container first-container">
        <p>{{ emptyText() }}</p>
    </div>
}

@for (item of items(); track item.entity.id) {
    <dv-accordion [expanded]="item.expanded"
                  [index]="$index"
                  (toggle)="toggleExpanded.emit(item.entity)">

        <ng-container accordionHeader *ngTemplateOutlet="headerTemplate()!; context:{$implicit: item, index: $index}"/>

        <ng-container accordionBody>
            @if (item.displayMode === 'edit') {
                <ng-container *ngTemplateOutlet="editModeTemplate()!; context:{$implicit: item, index: $index}"/>
            } @else if (item.displayMode === 'readonly') {
                <ng-container *ngTemplateOutlet="readonlyModeTemplate() ?? defaultReadonlyModeTemplate; context:{$implicit: item, index: $index}"/>
            }
        </ng-container>
    </dv-accordion>
}

@if (createModeTemplate(); as template) {
    <div class="big-abstand-top">
        @if (createMode()) {
            <ng-container *ngTemplateOutlet="template"/>
        } @else {
            <dvlib-button-list>
                <button type="button"
                        class="btn btn-primary"
                        (click)="showCreateMode.emit()">
                    {{ createText() }}
                </button>
            </dvlib-button-list>
        }
    </div>
}

<ng-template [dvCrudAccordionTemplate]="items()" let-item #defaultReadonlyModeTemplate>
    <dvlib-button-list>
        <button type="button"
                class="btn btn-link"
                (click)="editEntity.emit(item.entity)"
                translate="COMMON.BEARBEITEN">
        </button>
        <button type="button"
                class="btn btn-link"
                (click)="deleteEntity.emit(item.entity)"
                [translate]="'COMMON.LOESCHEN'">
        </button>
    </dvlib-button-list>
</ng-template>

<dvlib-confirm-dialog [open]="showDeleteDialog()"
                      [title]="'COMMON.CONFIRM_DELETE_ENTITY' | translate: {entity: entityName() | translate}"
                      [isLoading]="isLoading()"
                      [confirmActionText]="'COMMON.LOESCHEN' | translate"
                      [cancelActionText]="'COMMON.ABBRECHEN' | translate"
                      (confirm)="confirmDelete.emit()"
                      (cancel)="hideDeleteDialog.emit()"/>
