<form name="dateDialogForm" #dateDialogForm="ngForm" (ngSubmit)="submitForm(dateDialogForm)" novalidate>
    <div class="modal-body">
        <h1 [innerHTML]="title | translate"></h1>
        @if (subtitle) {
            <h2 [innerHTML]="subtitle | translate"></h2>
        }
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <dvlib-datepicker-textfield [(ngModel)]="endDate"
                                            name="endDate"
                                            (ngModelChange)="applyDateLimitation()"
                                            [placeholder]="placeholder || 'COMMON.ENDDATUM' | translate"
                                            required>
                </dvlib-datepicker-textfield>
            </div>
        </div>
    </div>
    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            @for (button of inverseButtons ? ['cancel', 'confirm'] : ['confirm', 'cancel'];
                track trackByIdentity($index, button)) {
                @if (button === 'confirm') {
                    <dvlib-loading-button
                        [type]="'confirm'"
                        [isLoading]="isLoading$ | async"
                        [asLink]="inverseButtons">
                        <span [translate]="confirmActionText || 'COMMON.FORTFAHREN'"></span>
                    </dvlib-loading-button>
                }
                @if (button === 'cancel') {
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="{
                              'btn-primary': inverseButtons,
                              'btn-link': !inverseButtons
                            }"
                        [translate]="cancelActionText || 'COMMON.ABBRECHEN'"
                        (click)="hide()">
                    </button>
                }
            }
        </dvlib-button-list>
    </div>
</form>
