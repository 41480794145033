<details [attr.open]="expanded() || null" (toggle)="detailsToggle($event)">
    <summary class="accordion-header"
             [ngClass]="{
              'selected-accordion': expanded(),
              'accordion-header-first': isFirst()
             }">
        <ng-content select="[accordionHeader]"/>
    </summary>

    @if (expanded()) {
        <div class="accordion-detail">
            <ng-content select="[accordionBody]"/>
        </div>
    }
</details>
