<form (ngSubmit)="submit(null)" novalidate>
    <div class="modal-body">
        <h1 [innerHTML]="title | translate"></h1>
        @if (subtitle) {
            <h2 [innerHTML]="subtitle | translate"></h2>
        }
    </div>
    <div class="modal-footer big-abstand-top">
        <dvlib-button-list>
            @for (button of inverseButtons ?
                ['cancel', 'confirm'] :
                ['confirm', 'cancel']; track trackByIdentity($index, button)) {
                @if (button === 'confirm') {
                    <dvlib-loading-button
                        [type]="'submit'"
                        [isLoading]="isLoading$ | async"
                        [spinnerRight]="inverseButtons"
                        [asLink]="inverseButtons">
                        <span [translate]="confirmActionText || 'COMMON.FORTFAHREN'"></span>
                    </dvlib-loading-button>
                }
                @if (button === 'cancel') {
                    <button
                        type="button"
                        class="btn"
                        [ngClass]="{
                              'btn-primary': inverseButtons,
                              'btn-link': !inverseButtons
                            }"
                        [translate]="cancelActionText || 'COMMON.ABBRECHEN'"
                        (click)="hide()">
                    </button>
                }
            }
        </dvlib-button-list>
    </div>
</form>
