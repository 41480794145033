import type {ConnectedPosition, OverlayRef} from '@angular/cdk/overlay';
import {Overlay, OverlayConfig, OverlayModule} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import type {OnChanges, SimpleChanges} from '@angular/core';
import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    DestroyRef,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import {checkPresent} from '@dv/shared/code';

// cdk picks whichever position fits best into the viewport
const POSITIONS: ConnectedPosition[] = [
    // position below element
    {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 15,
        panelClass: 'below',
    },
    // position above element
    {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -15,
        panelClass: 'above',
    },
];

@Component({
    selector: 'dv-overlay',
    template: '',
    standalone: true,
    imports: [OverlayModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayComponent implements OnChanges {

    @Input({transform: booleanAttribute}) public open: boolean = false;
    @Input() public connectedTo?: Element;
    @ContentChild(TemplateRef, {static: false}) private template!: TemplateRef<unknown>;

    @Output() public readonly closeOverlay = new EventEmitter<void>();

    private overlayRef?: OverlayRef;

    public constructor(
        private readonly overlay: Overlay,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly destroyRef: DestroyRef,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.open?.currentValue === true) {
            this.openOverlay();
        }

        if (changes.open?.currentValue === false) {
            this.overlayRef?.detach();
        }
    }

    private openOverlay(): void {
        const config = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(checkPresent(this.connectedTo))
                .withPositions(POSITIONS),
        });

        this.overlayRef?.detach();
        this.overlayRef = this.overlay.create(config);
        this.closeOverlay.subscribe(this.overlayRef.backdropClick());

        const portal = new TemplatePortal(this.template, this.viewContainerRef);

        this.overlayRef.attach(portal);
    }
}
