<form name="terminationModeForm" (ngSubmit)="submit()" novalidate>
    <div class="modal-body">
        <h1 [translate]="translationRoot + '.CONFIRM_BEENDEN'"
            [translateParams]="{date: endDate | dvLocalDateFormat}">
        </h1>

        <div class="row">
            <div class="col-md-12 text-left">
                @for (mode of terminationModes; track trackByIdentity($index, mode)) {
                    <div class="radio">
                        <label>
                            <input type="radio"
                                   name="{{mode}}"
                                   value="{{mode}}"
                                   [(ngModel)]="terminationMode"
                                   required>
                            <span [translate]="translationRoot + '.TERMINATION_MODE.' + mode"
                                  [translateParams]="{date: endDate | dvLocalDateFormat}">
                            </span>
                        </label>
                    </div>
                }
            </div>
        </div>
    </div>
    <div class="modal-footer big-abstand-top" *dvlibLet="(isLoading$ | async) || false as isLoading ">
        <dvlib-loading-button [isLoading]="isLoading">
            <span [translate]="'COMMON.BEENDEN'"></span>
        </dvlib-loading-button>
        <button type="button" class="btn btn-link" [translate]="'COMMON.ABBRECHEN'" (click)="hide()"></button>
    </div>
</form>
