<dvlib-button-list class="normal-abstand-top">
    <dvlib-loading-button type="submit" [isLoading]="isLoading">
        <span [translate]="submitLabel || 'COMMON.SPEICHERN'"></span>
    </dvlib-loading-button>
    <button type="button"
            class="btn btn-link"
            (click)="cancel.emit()"
            [translate]="cancelLabel || 'COMMON.ABBRECHEN'"
            [disabled]="isLoading">
    </button>
</dvlib-button-list>
