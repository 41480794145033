/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';

import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ButtonListComponent, LoadingButtonComponent, trackByIdentity} from '@dv/shared/angular';
import {TranslateModule} from '@ngx-translate/core';
import type {ConfirmDialogModel} from '../../models/ConfirmDialogModel';
import {CompletableDialogBase} from '../completable-dialog.base';

@Component({
    selector: 'dv-confirm',
    templateUrl: './confirm.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, FormsModule, TranslateModule, LoadingButtonComponent, ButtonListComponent],
})
export class ConfirmComponent extends CompletableDialogBase<null> implements ConfirmDialogModel<null> {

    @Input({required: true}) public title!: string;
    @Input() public subtitle?: string;
    @Input() public confirmActionText?: string;
    @Input() public cancelActionText?: string;
    @Input() public inverseButtons: boolean = false;

    public trackByIdentity = trackByIdentity;
}
