import {NgClass} from '@angular/common';
import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    computed,
    EventEmitter,
    Input,
    Output,
    signal,
} from '@angular/core';

@Component({
    selector: 'dv-accordion',
    standalone: true,
    imports: [NgClass],
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {

    public expanded = signal(false);
    public index = signal(0);

    @Input({required: true, transform: booleanAttribute, alias: 'expanded'})
    public set _expanded(value: boolean) {
        this.expanded.set(value);
    }

    @Input({required: true, alias: 'index'})
    public set _index(index: number) {
        this.index.set(index);
    }

    public isFirst = computed(() => this.index() === 0);
    private detailsState = computed(() => this.expanded() ? 'open' : 'closed');

    @Output() public readonly toggle: EventEmitter<void> = new EventEmitter();

    public detailsToggle($event: Event): void {
        // changing expanded from the outside triggers ToggleEvent. This avoids an endless loop.
        // use a type cast instead of instance of because ToggleEvent is not implemented in jsdom
        if (($event as any).newState !== this.detailsState()) {
            this.toggle.emit();
        }
    }
}
