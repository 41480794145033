/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive, Input} from '@angular/core';
import type {CrudAccordionTemplateContext} from './crud-accordion-template-context';

@Directive({
    selector: 'ng-template[dvCrudAccordionTemplate]',
    standalone: true,
})
export class CrudAccordionTemplateDirective<T> {

    @Input({required: true, alias: 'dvCrudAccordionTemplate'})
    public items!: T[];

    public static ngTemplateContextGuard<TContext>(
        dir: CrudAccordionTemplateDirective<TContext>,
        ctx: unknown,
    ): ctx is CrudAccordionTemplateContext<TContext> {
        return true;
    }
}
