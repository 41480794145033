/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal, TemplateRef} from '@angular/core';
import {ButtonListComponent, ConfirmDialogComponent, SpinnerComponent} from '@dv/shared/angular';
import type {IPersisted} from '@dv/shared/code';
import {TranslateModule} from '@ngx-translate/core';
import type {ItemWithEntity} from '../accordion.adapter';
import {AccordionComponent} from '../accordion.component';
import type {CrudAccordionTemplateContext} from './crud-accordion-template-context';
import {CrudAccordionTemplateDirective} from './crud-accordion-template.directive';

@Component({
    selector: 'dv-crud-accordion',
    standalone: true,
    imports: [
        AccordionComponent,
        ConfirmDialogComponent,
        SpinnerComponent,
        TranslateModule,
        NgTemplateOutlet,
        CrudAccordionTemplateDirective,
        ButtonListComponent,
    ],
    templateUrl: './crud-accordion.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrudAccordionComponent<Entity extends IPersisted,
    T extends ItemWithEntity<Entity> = ItemWithEntity<Entity>> {

    public items = signal<T[]>([]);

    public title = signal<string | undefined>(undefined);

    public isEmpty = signal<boolean>(true);
    public emptyText = signal<string>('');

    public createMode = signal<boolean>(false);
    public createText = signal<string | undefined>(undefined);
    public showDeleteDialog = signal<boolean>(false);
    public entityName = signal<string>('');

    public isLoading = signal<boolean>(false);

    public headerTemplate = signal<undefined | TemplateRef<CrudAccordionTemplateContext<T>>>(undefined);
    public editModeTemplate = signal<undefined | TemplateRef<CrudAccordionTemplateContext<T>>>(undefined);
    public readonlyModeTemplate = signal<undefined | TemplateRef<CrudAccordionTemplateContext<T>>>(undefined);
    public createModeTemplate = signal<undefined | TemplateRef<CrudAccordionTemplateContext<T>>>(undefined);

    @Input({required: true, alias: 'items'})
    public set _items(items: T[]) {
        this.items.set(items);
    }

    @Input({alias: 'title'})
    public set _title(value: string) {
        this.title.set(value);
    }

    @Input({required: true, alias: 'isEmpty'})
    public set _isEmpty(value: boolean) {
        this.isEmpty.set(value);
    }

    @Input({required: true, alias: 'emptyText'})
    public set _emptyText(value: string) {
        this.emptyText.set(value);
    }

    @Input({alias: 'createMode'})
    public set _createMode(value: boolean) {
        this.createMode.set(value);
    }

    @Input({alias: 'createText'})
    public set _createText(value: string) {
        this.createText.set(value);
    }

    @Input({alias: 'showDeleteDialog'})
    public set _showDeleteDialog(value: boolean) {
        this.showDeleteDialog.set(value);
    }

    @Input({alias: 'entityName'})
    public set _entityName(value: string) {
        this.entityName.set(value);
    }

    @Input({alias: 'isLoading'})
    public set _isLoading(value: boolean) {
        this.isLoading.set(value);
    }

    @Input({required: true, alias: 'headerTemplate'})
    public set _headerTemplate(value: TemplateRef<CrudAccordionTemplateContext<T>>) {
        this.headerTemplate.set(value);
    }

    @Input({required: true, alias: 'editModeTemplate'})
    public set _editModeTemplate(value: TemplateRef<CrudAccordionTemplateContext<T>>) {
        this.editModeTemplate.set(value);
    }

    @Input({alias: 'readonlyModeTemplate'})
    public set _readonlyModeTemplate(value: TemplateRef<CrudAccordionTemplateContext<T>>) {
        this.readonlyModeTemplate.set(value);
    }

    @Input({alias: 'createModeTemplate'})
    public set _createModeTemplate(value: TemplateRef<CrudAccordionTemplateContext<T>>) {
        this.createModeTemplate.set(value);
    }

    @Output() public readonly toggleExpanded: EventEmitter<T['entity']> = new EventEmitter();
    @Output() public readonly editEntity: EventEmitter<T['entity']> = new EventEmitter();
    @Output() public readonly deleteEntity: EventEmitter<T['entity']> = new EventEmitter();
    @Output() public readonly confirmDelete: EventEmitter<void> = new EventEmitter();
    @Output() public readonly hideDeleteDialog: EventEmitter<void> = new EventEmitter();
    @Output() public readonly showCreateMode: EventEmitter<void> = new EventEmitter();
}
