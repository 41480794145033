/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {inject, Injectable, Input} from '@angular/core';
import {FunctionType, TypeUtil} from '@dv/shared/code';
import {BsModalRef} from 'ngx-bootstrap/modal';
import type {Observable} from 'rxjs';
import {BehaviorSubject, take} from 'rxjs';
import type {CompletableDialogModel} from '../models/CompletableDialogModel';

// false positive for abstract classes
// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export abstract class CompletableDialogBase<T> implements CompletableDialogModel<T> {

    @Input() public confirm!: (value: T) => Observable<unknown>;
    @Input() public cancel?: FunctionType;

    private readonly isLoadingSubject$ = new BehaviorSubject(false);
    public readonly isLoading$ = this.isLoadingSubject$.asObservable();

    protected bsModalRef?: BsModalRef = inject(BsModalRef);

    public submit(value: T): void {
        if (!TypeUtil.isFunction(this.confirm)) {
            this.bsModalRef?.hide();

            return;
        }

        this.isLoadingSubject$.next(true);

        this.confirm(value).pipe(take(1)).subscribe({
            next: _ => {
                this.isLoadingSubject$.next(false);
                this.bsModalRef?.hide();
            },
            error: () => {
                this.isLoadingSubject$.next(false);
            },
        });
    }

    public hide(): void {
        this.bsModalRef?.hide();
        if (TypeUtil.isFunction(this.cancel)) {
            this.cancel();
        }
    }
}
